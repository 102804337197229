@if ({
projects: projects$ | async,
worksites: worksites$ | async
}; as vm) {
  <app-main-dialog (dismiss)="dismiss()">
    <div class="flex-title"
      desktopTitle>
      {{ mainSheet()?.date }}
      <div class="flex-end">
        @if (!showSplit()) {
          <ion-button shape="round"
            color="secondary"
            fill="solid"
            (click)="startSplit()">
            <div class="ion-padding05">
              {{ 'timesheet.split' | translate }}
            </div>
          </ion-button>
        } @else {
          <ion-button color="danger"
            shape="round"
            fill="solid"
            (click)="cancelSplit()">
            <div class="ion-padding05">
              {{ 'general.cancel' | translate }}
            </div>
          </ion-button>
        }
      </div>
    </div>
    <div>
      <div [hidden]="showSplit()">
        <div class="flex">
          <ion-button id="adHocDuration"
            color="secondary"
            shape="round">
            @if (mainHours(); as duration) {
              <span>
                {{ duration | hours }}
              </span>
            } @else {
              {{ 'paycheck.hours' | translate }}
            }
          </ion-button>
          <ion-modal #durationModal
            class="picker-modal"
            trigger="adHocDuration"
            [keepContentsMounted]="true">
            <ng-template>
              <ion-toolbar>
                <ion-buttons slot="start">
                  <ion-button (click)="durationModal.dismiss()">
                    {{ 'general.cancel' | translate }}
                  </ion-button>
                </ion-buttons>
                <ion-buttons>
                  <ion-button color="danger"
                    (click)="onDuration(0, mainHours); durationModal.dismiss()">
                    {{ 'general.reset' | translate }}
                  </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                  <ion-button (click)="onDuration(durationPicker.value, mainHours); durationModal.dismiss()">
                    {{ 'general.select' | translate }}
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
              <ion-picker>
                <ion-picker-column #durationPicker
                  [value]="mainHours()">
                  @for (duration of hoursArray; track duration.value) {
                    <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                  }
                </ion-picker-column>
              </ion-picker>
            </ng-template>
          </ion-modal>
          <ion-button shape="round"
            color="light"
            (click)="selectProject(vm.projects)">
            @if (selectedProject()?.name) {
              {{ selectedProject().name }}
            } @else {
              {{ 'sites.new.project' | translate }}
            }
          </ion-button>
          <ion-button shape="round"
            color="light"
            (click)="changeSite(vm.worksites)">
            @if (site$ | async; as site) {
              {{ site.name }}
            }
          </ion-button>
        </div>
      </div>
      <div [hidden]="!showSplit()">
        <div class="flex">
          <div>
            <div class="split-title">{{ 'timesheet.currentTimesheet' | translate }}</div>
            <ion-button color="light"
              disabled="true">
              @if (site$ | async; as site) {
                <ion-label>{{ site.name }}</ion-label>
              }
            </ion-button>
            <div>
              <ion-button color="light"
                disabled="true">
                {{ selectedProject()?.name }}
              </ion-button>
            </div>
            <ion-button id="splitHours1"
              color="secondary"
              shape="round">
              @if (split1Hours(); as duration) {
                <span>
                  {{ duration | hours }}
                </span>
              } @else {
                {{ 'paycheck.hours' | translate }}
              }
            </ion-button>
            <ion-modal #split1Modal
              class="picker-modal"
              trigger="splitHours1"
              [keepContentsMounted]="true">
              <ng-template>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button (click)="split1Modal.dismiss()">
                      {{ 'general.cancel' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons>
                    <ion-button color="danger"
                      (click)="onDuration(0, split1Hours); split1Modal.dismiss()">
                      {{ 'general.reset' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons slot="end">
                    <ion-button (click)="onDuration(split1Picker.value, split1Hours); split1Modal.dismiss()">
                      {{ 'general.select' | translate }}
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <ion-picker>
                  <ion-picker-column #split1Picker
                    [value]="split1Hours()">
                    @for (duration of hoursArray; track duration.value) {
                      <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                    }
                  </ion-picker-column>
                </ion-picker>
              </ng-template>
            </ion-modal>
          </div>
          <div>
            <div class="split-title">{{ 'timesheet.newTimesheet' | translate }}</div>
            @if (showSplit()) {
              <ion-button shape="round"
                color="light"
                (click)="onAnotherSiteSelect(vm.worksites)">
                @if (siteAnother()?.name) {
                  {{ siteAnother().name }}
                } @else {
                  {{ 'report.selectSite' | translate }}
                }
              </ion-button>
            }
            <div>
              <ion-button shape="round"
                color="light"
                (click)="onSelectSplitProject(vm.projects)">
                @if (projectAnother()?.name) {
                  {{ projectAnother().name }}
                } @else {
                  {{ 'sites.new.project' | translate }}
                }
              </ion-button>
            </div>
            <ion-button id="splitHours2"
              color="secondary"
              shape="round">
              @if (split2Hours(); as duration) {
                <span>
                  {{ duration | hours }}
                </span>
              } @else {
                {{ 'paycheck.hours' | translate }}
              }
            </ion-button>
            <ion-modal #split2Modal
              class="picker-modal"
              trigger="splitHours2"
              [keepContentsMounted]="true">
              <ng-template>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button (click)="split2Modal.dismiss()">
                      {{ 'general.cancel' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons>
                    <ion-button color="danger"
                      (click)="onDuration(0, split2Hours); split2Modal.dismiss()">
                      {{ 'general.reset' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons slot="end">
                    <ion-button (click)="onDuration(split2Picker.value, split2Hours); split2Modal.dismiss()">
                      {{ 'general.select' | translate }}
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <ion-picker>
                  <ion-picker-column #split2Picker
                    [value]="split2Hours()">
                    @for (duration of hoursArray; track duration.value) {
                      <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                    }
                  </ion-picker-column>
                </ion-picker>
              </ng-template>
            </ion-modal>
          </div>
        </div>
      </div>
      <div class="ion-margin-top divider"></div>
      <div class="ion-margin-top">
        <div class="flex">
          <ion-button color="success"
            shape="round"
            [disabled]="disableButton() || checked()"
            (click)="onEditTimesheet(true)">
            @if (checked()) {
              <div>
                {{ 'kanban.status.checked' | translate }}
              </div>
            } @else {
              {{ 'timesheet.accept' | translate }}
            }
          </ion-button>
          <ion-button color="light"
            shape="round"
            [disabled]="disableButton()"
            (click)="onEditTimesheet(true, true)">
            {{ 'site.invoice.invoiced' | translate }}
          </ion-button>
          <ion-button color="secondary"
            shape="round"
            [disabled]="disableButton()"
            (click)="onEditTimesheet(true, false, true)">
            {{ 'timesheet.wontInvoice' | translate }}
          </ion-button>
        </div>
        <div class="ion-margin-top">
          <ion-button color="light"
            shape="round"
            (click)="onEditTimesheet(false)"
            [disabled]="disableButton()">{{ 'general.save' | translate }}
          </ion-button>
        </div>
      </div>
    </div>
  </app-main-dialog>
}