@if ({
  role: role$ | async,
  site: site$ | async,
  contractor: contractor$ | async,
  projects: projects$ | async
  }; as vm) {
  <app-segment-list segmentContainer
    [segmentButtons]="segmentButtons$ | async"></app-segment-list>
  @if (worksites$ | async; as worksites) {
    <div class="no-print">
      <ion-button shape="round"
        color="light"
        (click)="selectSite(worksites)">
        @if (site(); as site) {
          <span>
            {{ site.name }}
          </span>
        } @else {
          {{ 'report.selectSite' | translate }}
        }
      </ion-button>
    </div>
  }
  @if (vm.site) {
    @if (vm.projects?.length > 0) {
      <div class="no-print">
        <ion-button color="light"
          shape="round"
          (click)="selectProject(vm.projects)">
          @if (selectedProject$ | async; as selectedProject) {
            <ion-label>
              {{ selectedProject.name }}
            </ion-label>
          } @else {
            <ion-label>
              {{ 'report.selectProject' | translate }}
            </ion-label>
          }
        </ion-button>
      </div>
    }
    <div class="ion-margin days no-print">
      <app-selected-day [currentDay]="startDay()"
        [id]="'startReport-' + vm.site.guid"
        (changed)="onDayChange($event, null)"></app-selected-day>
      <div>-</div>
      <app-selected-day [currentDay]="endDay()"
        [id]="'endReport-' + vm.site.guid"
        (changed)="onDayChange(null, $event)"></app-selected-day>
    </div>
    <app-content>
      <div class="flex-end">
        <ion-button shape="round"
          (click)="toggleShowInformation()">
          {{ 'report.showInformations' | translate }}
        </ion-button>
      </div>
      <div [id]="vm.site.guid + '-container'"
        class="ion-padding">
        @if (timesheets$ | async; as sheets) {
          @if (workers().length === 0) {
            <p>
              {{ 'report.noWorkers' | translate }}
            </p>
          }
          <div [id]="vm.site.guid + '-report-print'"
            class="desktop">
            <div class="print ion-margin-bottom">
              <h1 class="flex title">
                <span>{{ 'report.title' | translate }}</span>
                <span>
                  {{ startDay() }}&nbsp;-&nbsp;{{ endDay() }}
                </span>
              </h1>
              <div class="ion-margin-bottom flex-justify-between">
                <div>
                  <div class="ion-margin-bottom">
                    {{ 'report.clientTitle' | translate }}: <span class="strong">{{ vm.site.clientName$ | async
                    }}</span>
                  </div>
                  <div class="ion-margin-bottom">
                    {{ 'timesheet.site' | translate }}: <span class="strong">{{ vm.site.name }}</span>
                  </div>
                  @if (selectedProject$ | async; as selectedProject) {
                    <div class="ion-margin-bottom">
                      {{ 'timesheet.project' | translate }}: <span class="strong">{{ selectedProject.name }}</span>
                    </div>
                  }
                  <div class="ion-margin-bottom">
                    {{ 'report.reportRange' | translate }}: <span class="strong">{{ startDay() }}. - {{ endDay()
                    }}.</span>
                  </div>
                </div>
                <div>
                  {{ vm.contractor?.name }}
                </div>
              </div>
            </div>
            @if (workers().length > 0) {
              <table id="printable"
                class="printable sheet">
                <thead>
                  <tr class="header sticky">
                    <td>{{ 'report.weekday' | translate }}</td>
                    <td> </td>
                    @for (user of workers(); track user) {
                      <td>
                        {{ user?.user?.displayName }}
                      </td>
                    }
                    <td>{{ 'report.overtime50' | translate }}</td>
                    <td>{{ 'report.overtime100' | translate }}</td>
                    <td>{{ 'report.summary' | translate }}</td>
                    @if (showInformation()) {
                      <td class="no-print">{{ 'report.information' | translate }}</td>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (sheet of sheets; track sheet) {
                    <tr [class.weekend]="sheet.weekday === 0 || sheet.weekday === 6">
                      <td class="width">{{ sheet.weekday | weekday }} </td>
                      <td class="width">{{ sheet.date }}</td>
                      @for (user of workers(); track user) {
                        <td class="link width"
                          [class.invoiced]="isInvoiced(sheet, user.user)"
                          [class.wontInvoice]="isWontInvoice(sheet, user.user)"
                          [class.checked]="isChecked(sheet, user.user)">
                          @if (user) {
                            <div class="flex">
                              <div class="clickable"
                                (click)="startEdit(sheet, user.user, vm.role, vm.projects)">
                                @if (getWorkingHours(user.user.guid, sheet.timesheets); as hours) {
                                  <span>
                                    {{ hours | hours }}
                                  </span>
                                } @else {
                                  <div class="empty"></div>
                                }
                              </div>
                              @if (user && hasFlagged(user.user, sheet.timesheets)) {
                                <ion-icon class="link"
                                  color="warning no-print"
                                  name="warning-outline"
                                  (click)="openTimesheet(user.user, sheet.timesheets)"></ion-icon>
                              }
                            </div>
                          }
                        </td>
                      }
                      <td class="width">{{ getOverTime50(sheet.timesheets) | hours }}</td>
                      <td class="width">{{ getOverTime100(sheet.timesheets) | hours }}</td>
                      <td class="width">{{ getSummaryRow(sheet.timesheets) | hours }}</td>
                      @if (showInformation()) {
                        @if ({
                              schedule: sheet.schedule$ | async
                            }; as sheetVm) {
                          <td class="link no-print"
                            (click)="onInformation(sheet, sheet.schedule$, vm.projects)">
                            @if (getUserInformation(sheetVm.schedule, sheet.timesheets); as informations) {
                              @for (info of informations; track info) {
                                <div>
                                  {{ info }}
                                </div>
                              }
                            }
                          </td>
                        }
                      }
                    </tr>
                  }
                  <tr class="footer">
                    <td colspan="2"
                      class="ion-no-border"></td>
                    @for (user of workers(); track user) {
                      <td class="ion-no-border"> </td>
                    }
                    <td>{{ getOverTime50All(sheets) | hours }}</td>
                    <td>{{ getOverTime100All(sheets) | hours }}</td>
                    <th> {{ getAllSummary(sheets) | hours }}</th>
                    @if (showInformation()) {
                      <td class="empty no-print"></td>
                    }
                  </tr>
                </tbody>
              </table>
            }
            <div class="ion-margin-top print">
              <div>
                {{ 'report.client' | translate }}
              </div>
              <div class="signature-container"></div>
              <div>{{ 'report.signature' | translate }}</div>
              <div class="ion-margin-top">
                <h3>{{ 'report.tasks' | translate }}</h3>
                @for (user of workers(); track user) {
                  <div>
                    <h4>{{ user.user?.displayName }}</h4>
                    <table class="printable sheet">
                      <thead>
                        <tr class="header sticky">
                          <td></td>
                          <td></td>
                          <td>{{ 'report.tickets' | translate }}</td>
                        </tr>
                      </thead>
                      @if (user.tickets$ | async; as tickets) {
                        <tbody>
                          @for (ticket of tickets; track ticket) {
                            <tr>
                              <td class="width">{{ 'weekdays.' + ticket.weekday | translate }}</td>
                              <td class="width">{{ ticket.date | date:'d.M.yyyy' }}</td>
                              <td>{{ ticket.name }}</td>
                            </tr>
                          }
                          @if (tickets.length === 0) {
                            <tr>
                              <td colspan="3">
                                {{ 'report.noTickets' | translate }}
                              </td>
                            </tr>
                          }
                        </tbody>
                      }
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (workers().length > 0) {
            <div class="mobile">
              <swiper-container [scrollbar]="true"
                [modules]="swiperModules">
                @for (user of workers(); track user) {
                  <swiper-slide>
                    <div class="full-width">
                      <table class="printable sheet full-width">
                        <tr class="header">
                          <td>{{ 'report.weekday' | translate }}</td>
                          <td></td>
                          <td>{{ user?.user?.displayName }}</td>
                        </tr>
                        @for (sheet of sheets; track sheet) {
                          <tr>
                            <td class="width">{{ sheet.weekday | weekday }} </td>
                            <td class="width">{{ sheet.date }}</td>
                            <td class="link"
                              [class.invoiced]="isInvoiced(sheet, user.user)"
                              [class.wontInvoice]="isWontInvoice(sheet, user.user)"
                              [class.checked]="isChecked(sheet, user.user)">
                              @if (user) {
                                <div class="flex">
                                  <div class="clickable"
                                    (click)="startEdit(sheet, user.user, vm.role, vm.projects)">
                                    @if (getWorkingHours(user.user.guid, sheet.timesheets); as hours) {
                                      <span>
                                        {{ hours }}
                                      </span>
                                    } @else {
                                      <div class="empty"></div>
                                    }
                                  </div>
                                  @if (user && hasFlagged(user.user, sheet.timesheets)) {
                                    <ion-icon class="link"
                                      color="warning"
                                      name="warning-outline"
                                      (click)="openTimesheet(user.user, sheet.timesheets)"></ion-icon>
                                  }
                                </div>
                              }
                            </td>
                          </tr>
                        }
                        <tr class="footer">
                          <th colspan="2">
                            {{ 'report.summary' | translate }}
                          </th>
                          <th>
                            {{ getSummary(user.user.guid, sheets) }}
                          </th>
                        </tr>
                      </table>
                      <div class="empty"></div>
                    </div>
                  </swiper-slide>
                }
                <swiper-slide>
                  <div class="full-width">
                    <table class="printable sheet full-width">
                      <tr class="header">
                        <td>{{ 'report.weekday' | translate }}</td>
                        <td></td>
                        <td>{{ 'report.summary' | translate }}</td>
                      </tr>
                      @for (sheet of sheets; track sheet) {
                        <tr>
                          <td class="width">{{ sheet.weekday | weekday }} </td>
                          <td class="width">{{ sheet.date }}</td>
                          <td>
                            {{ getSummaryRow(sheet.timesheets) }}
                          </td>
                        </tr>
                      }
                      <tr class="footer">
                        <th colspan="2">
                          {{ 'report.summary' | translate }}
                        </th>
                        <th>
                          {{ getAllSummary(sheets) }}
                        </th>
                      </tr>
                    </table>
                    <div class="empty"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="full-width">
                    <table class="printable sheet full-width">
                      <tr class="header">
                        <td>{{ 'report.weekday' | translate }}</td>
                        <td></td>
                        <td>{{ 'report.persons' | translate }}</td>
                      </tr>
                      @for (sheet of sheets; track sheet) {
                        <tr>
                          <td class="width">{{ sheet.weekday | weekday }} </td>
                          <td class="width">{{ sheet.date }}</td>
                          <td>
                            {{ getPersons(sheet.timesheets) }}
                          </td>
                        </tr>
                      }
                      <tr class="footer">
                      </tr>
                    </table>
                    <div class="empty"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="full-width">
                    <table class="printable sheet full-width">
                      <tr class="header">
                        <td>{{ 'report.weekday' | translate }}</td>
                        <td></td>
                        <td>{{ 'report.overtime50' | translate }}</td>
                      </tr>
                      @for (sheet of sheets; track sheet) {
                        <tr>
                          <td class="width">{{ sheet.weekday | weekday }} </td>
                          <td class="width">{{ sheet.date }}</td>
                          <td>
                            {{ getOverTime50(sheet.timesheets) }}
                          </td>
                        </tr>
                      }
                      <tr class="footer">
                        <th colspan="2">
                          {{ 'report.summary' | translate }}
                        </th>
                        <th>
                          {{ getOverTime50All(sheets) }}
                        </th>
                      </tr>
                    </table>
                    <div class="empty"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="full-width">
                    <table class="printable sheet full-width">
                      <tr class="header">
                        <td>{{ 'report.weekday' | translate }}</td>
                        <td></td>
                        <td>{{ 'report.overtime100' | translate }}</td>
                      </tr>
                      @for (sheet of sheets; track sheet) {
                        <tr>
                          <td class="width">{{ sheet.weekday | weekday }} </td>
                          <td class="width">{{ sheet.date }}</td>
                          <td>
                            {{ getOverTime100(sheet.timesheets) }}
                          </td>
                        </tr>
                      }
                      <tr class="footer">
                        <th colspan="2">
                          {{ 'report.summary' | translate }}
                        </th>
                        <th>
                          {{ getOverTime100All(sheets) }}
                        </th>
                      </tr>
                    </table>
                    <div class="empty"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="full-width">
                    <table class="printable sheet full-width">
                      <tr class="header">
                        <td>{{ 'report.weekday' | translate }}</td>
                        <td></td>
                        <td>{{ 'report.information' | translate }}</td>
                      </tr>
                      @for (sheet of sheets; track sheet) {
                        <tr>
                          <td class="width">{{ sheet.weekday | weekday }} </td>
                          <td class="width">{{ sheet.date }}</td>
                          @if ({
                                schedule: sheet.schedule$ | async
                              }; as sheetVm) {
                            <td class="link"
                              (click)="onInformation(sheet, sheet.schedule$, vm.projects)">
                              @if (getUserInformation(sheetVm.schedule, sheet.timesheets); as informations) {
                                @for (info of informations; track info) {
                                  <div>
                                    {{ info }}
                                  </div>
                                }
                              }
                            </td>
                          }
                        </tr>
                      }
                      <tr class="footer">
                      </tr>
                    </table>
                    <div class="empty"></div>
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
          }
        }
      </div>
    </app-content>
    <div class="flex-end ion-margin-top no-print">
      <ion-button color="light"
        shape="round"
        (click)="customPrint(vm.site)">
        {{ 'general.print' | translate }}
      </ion-button>
    </div>
    }
    }