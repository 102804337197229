import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonicSlides, ModalController } from '@ionic/angular/standalone';
import { LoadingService } from '@scandium-oy/ngx-scandium';
import { addWeeks, endOfDay, format, parse, startOfToday } from 'date-fns';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { ContentComponent } from 'src/app/components/content/content.component';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { SelectSiteDialog } from 'src/app/components/select-site/select-site.dialog';
import { SelectedDayComponent } from 'src/app/day/selected-day/selected-day.component';
import { Schedule } from 'src/app/models/schedule.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { TicketStatus } from 'src/app/models/ticket.model';
import { Timesheet } from 'src/app/models/timesheet.model';
import { User } from 'src/app/models/user.model';
import { HoursPipe } from 'src/app/pipes/duration/hours.pipe';
import { WeekdayPipe } from 'src/app/pipes/weekday/weekday.pipe';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { AreasService } from 'src/app/services/areas.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlusService } from 'src/app/services/plus.service';
import { PrintService } from 'src/app/services/print.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UpdateService } from 'src/app/services/update.service';
import { UsersService } from 'src/app/services/users.service';
import { TimesheetDialogComponent } from 'src/app/timesheets/timesheet/timesheet.dialog';
import { getDateFromTimestamp } from 'src/app/utility/item';
import { memo } from 'src/app/utility/memo';
import { Roles } from 'src/app/utility/role';
import { fieldSorter, sortAnyByDate } from 'src/app/utility/sort';
import {
  reportMillisToMinutesAndSeconds, selectedDayFormat,
} from 'src/app/utility/time';
import { getDurationsAsString } from 'src/app/utility/timesheet';
import { ISiteTimesheet, getOver100, getOver50, getWorkingHours, isChecked, isInvoiced, isWontInvoice } from '../helper';
import { IInformation, SetInformationComponent } from './dialog/set-information/set-information.dialog';
import { SetTimesheetComponent } from './dialog/timesheet/set-timesheet.dialog';
import { css } from './report-style';

interface TicketInfo {
  weekday: number;
  date: Date;
  name: string;
  project: SiteProject;
}

interface AppWorker {
  user: User;
  tickets$: Observable<TicketInfo[]>;
}

@Component({
  selector: 'app-report-site',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    SelectedDayComponent,
    WeekdayPipe,
    ContentComponent,
    HoursPipe,
    SegmentListComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReportSiteComponent {

  private startDate$ = new BehaviorSubject<Date>(null);
  private endDate$ = new BehaviorSubject<Date>(null);
  private selectedSite$ = new BehaviorSubject<Site>(null);
  private filterWorksites$ = new BehaviorSubject<string>('');
  private users$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => [Roles.admin, Roles.superAdmin].includes(user.role) ? this.usersService.getListOnce({ contractor: user.contractors[0] }) : this.usersService.getMyList()),
    distinctUntilChanged(),
  );

  swiperModules = [IonicSlides];

  site = signal<Site>(null);
  site$ = this.selectedSite$.asObservable().pipe(
    tap((site) => this.site.set(site)),
    shareReplay(1),
  );

  worksites$: Observable<Site[]> = combineLatest([
    this.sitesService.getAdminList().pipe(take(1)),
    this.route.queryParams,
  ]).pipe(
    tap(([sites, params]) => {
      const paramId = params.site;
      if (paramId) {
        if (params.startDay && params.endDay) {
          const startDay = parse(params.startDay, selectedDayFormat, new Date());
          const endDay = parse(params.endDay, selectedDayFormat, new Date());
          this.onDayChange(startDay, endDay);
        } else {
          this.selectToday();
        }
        const site = sites.find((s) => s.guid === paramId);
        this.onSelect(site);
      } else if (sites.length === 1) {
        this.onSelect(sites[0]);
      }
    }),
    map(([sites]) => sites),
    map((sites) => sites.map((site) => {
      site.clientName = this.clientsService.getSync(site.client)?.name ?? site.client ?? '';
      return site;
    })),
    map((sites) => sites?.sort(fieldSorter(['clientName', 'name']))),
    switchMap((sites) => combineLatest([this.filterWorksites$, of(sites)])),
    map(([filterValue, sites]) => sites.filter((site) =>
      site.name.toLowerCase().includes(filterValue.toLowerCase())
      || site.clientName.toLowerCase().includes(filterValue.toLowerCase()),
    )),
    shareReplay(1),
  );

  startDay = signal<string>(null);
  endDay = signal<string>(null);
  selectedProject$ = new BehaviorSubject<SiteProject>(null);

  workers = signal<AppWorker[]>([]);

  timesheets$: Observable<ISiteTimesheet[]> = combineLatest([
    this.startDate$,
    this.endDate$,
    this.selectedSite$,
    this.selectedProject$,
    this.contractorsService.getCurrentContractor(),
    this.updateService.getUpdate().pipe(
      debounceTime(100),
      startWith(null),
    ),
  ]).pipe(
    filter(([startDate, endDate, site]) => startDate != null && endDate != null && site != null),
    debounceTime(500),
    tap(([startDate, endDate, site, project]) =>
      this.navigationService.updateQueryParams({
        site: site?.guid, project: project?.guid,
        startDay: startDate?.toLocaleDateString('fi'), endDay: endDate?.toLocaleDateString('fi'), tab: 'report',
      })),
    switchMap(([startDate, endDate, site, project, contractor]) => combineLatest([
      this.timesheetsService.getList({ site: site.guid, startDate, endDate, project: project?.guid }),
      this.users$,
      this.areasService.getAreaTickets(of([site]), contractor?.guid),
      this.projects$,
    ])),
    filter(([_, users]) => users.length > 1),
    distinctUntilChanged(),
    map(([timesheets, users, allTickets, projects]) => {
      const startDate = new Date(this.startDate$.getValue().getTime());
      const endDate = this.endDate$.getValue();
      const sheets: ISiteTimesheet[] = [];
      const usersSet = new Set<User>();
      while (startDate <= endDate) {
        const date = startDate.toLocaleDateString('fi');
        const timesheetsDay = timesheets.filter((t) => t.date === date);
        timesheetsDay.map((t) => {
          const user = users.find((u) => u.guid === t.user);
          if (user != null) {
            usersSet.add(user);
          }
        });
        const item: ISiteTimesheet = {
          weekday: startDate.getDay(),
          date,
          timesheets: timesheetsDay,
          schedule$: this.schedulesService.getSingle({ site: this.selectedSite$.getValue().guid, date }).pipe(shareReplay(1)),
          users: timesheetsDay.map((ts) => ts.user),
        };
        sheets.push(item);
        startDate.setDate(startDate.getDate() + 1);
      }
      const initialStartDate = new Date(this.startDate$.getValue().getTime());
      const workerSet = Array.from(usersSet).map((user) => {
        const workerTickets = this.areasService.getUserTickets(allTickets, user.guid, TicketStatus.checked, initialStartDate, endOfDay(endDate))
          .map((it) => ({ weekday: it.date?.getDay(), date: it.date, name: it.name, project: null }) as TicketInfo);
        const schedules$ = this.schedulesService.getListOnce({
          site: this.selectedSite$.getValue().guid,
          user: user.guid,
          dateBetween: { start: initialStartDate, end: endDate },
        }).pipe(
          take(1),
          shareReplay(1),
        );
        const tickets$ = this.getUsersInfos(user, schedules$, timesheets, projects).pipe(
          map((informations) => workerTickets.concat(informations).sort((a, b) => sortAnyByDate(a, b, 'date'))),
          switchMap((informations) => combineLatest([of(informations), this.selectedProject$.asObservable()])),
          map(([informations, project]) => informations.filter((info) => info.project === null || project === null || info.project.guid === project.guid)),
          shareReplay(1),
        );
        return { user, tickets$ } as AppWorker;
      });
      this.workers.set(workerSet);
      return sheets;
    }),
    shareReplay(1),
  );

  contractor$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    shareReplay(1),
  );

  projects$ = combineLatest([this.selectedSite$.asObservable(), this.contractorsService.getCurrentContractor(), this.route.queryParams]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    switchMap(([site, contractor, params]) => this.siteProjectsService.getList({ site: site.guid, contractor: contractor.guid }).pipe(
      tap((projects) => {
        if (params.project) {
          const project = projects.find((it) => it.guid === params.project) ?? null;
          this.selectedProject$.next(project);
        }
      }),
    )),
    shareReplay(1),
  );

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  segmentButtons$ = combineLatest([this.site$, this.role$]).pipe(
    filter(([site, role]) => site != null && role != null),
    map(([site, role]) => [
      {
        name: 'timesheet.new',
        action: () => this.onNewTimesheet(site),
        cssClass: 'desktop',
        show: [Roles.admin, Roles.storage, Roles.worker].includes(role),
      },
    ]),
    shareReplay(1),
  );

  getUserInformation = memo((schedule: Schedule, timesheets: Timesheet[]) => this._getUserInformation(schedule, timesheets));
  getSummaryRow = memo((timesheets: Timesheet[]) => getDurationsAsString(timesheets));
  getPersons = memo((timesheets: Timesheet[]) => this._getPersons(timesheets));
  getOverTime50 = memo((timesheets: Timesheet[]) => this._getOverTime50(timesheets));
  getOverTime100 = memo((timesheets: Timesheet[]) => this._getOverTime100(timesheets));
  getSummary = memo((userGuid: string, sheets: ISiteTimesheet[]) => this._getSummary(userGuid, sheets));
  getAllSummary = memo((sheets: ISiteTimesheet[]) => this._getAllSummary(sheets));
  getOverTime50All = memo((sheets: ISiteTimesheet[]) => this._getOverTime50All(sheets));
  getOverTime100All = memo((sheets: ISiteTimesheet[]) => this._getOverTime100All(sheets));
  hasFlagged = memo((user: User, timesheets: Timesheet[]) => this._hasFlagged(user, timesheets));
  isChecked = memo((sheet: ISiteTimesheet, user: User) => isChecked(sheet, user));
  isInvoiced = memo((sheet: ISiteTimesheet, user: User) => isInvoiced(sheet, user));
  isWontInvoice = memo((sheet: ISiteTimesheet, user: User) => isWontInvoice(sheet, user));
  getWorkingHours = memo((userGuid: string, timesheets: Timesheet[]) => getWorkingHours(userGuid, timesheets, 'user'));

  showInformation = signal(false);

  constructor(
    private route: ActivatedRoute,
    private areasService: AreasService,
    private clientsService: ClientsService,
    private contractorsService: ContractorsService,
    private loadingService: LoadingService,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private plusService: PlusService,
    private printService: PrintService,
    private schedulesService: SchedulesService,
    private sitesService: SitesService,
    private siteProjectsService: SiteProjectsService,
    private timesheetsService: TimesheetsService,
    private updateService: UpdateService,
    private usersService: UsersService,
  ) { }

  private selectToday() {
    const now = startOfToday();
    const start = addWeeks(now, -2);
    this.onDayChange(start, now);
  }

  private getUsersInfos(user: User, schedules$: Observable<Schedule[]>, timesheets: Timesheet[], projects: SiteProject[]) {
    return schedules$.pipe(
      take(1),
      map((schedules) => {
        const informations: TicketInfo[] = [];
        const infos = schedules.map((it) => {
          const scheduleProject = it.projects.find((p) => p.user === user.guid);
          return {
            weekday: getDateFromTimestamp(it.dateObj)?.getDay(),
            date: getDateFromTimestamp(it.dateObj),
            name: it.information.find((info) => info.user === user.guid)?.text ?? null,
            project: projects.find((p) => p.guid === scheduleProject?.project) ?? null,
          };
        }).filter((it) => it.name != null);
        if (infos.length > 0) {
          informations.push(...infos);
        }
        const ret = timesheets.filter((ts) => ts.user === user.guid).map((ts) => {
          if (ts.information) {
            const project = projects?.find((p) => p.guid === ts.project);
            return { weekday: ts.dateIn?.getDay(), date: ts.dateIn, name: `${ts.information} (${project?.name ?? ''})`, project };
          }
        }).filter((info) => info != null);
        informations.push(...ret);
        return informations;
      }),
    );
  }

  private _getUserInformation(schedule: Schedule, timesheets: Timesheet[]): string[] {
    const information: string[] = [];
    const workers = this.workers();
    if (schedule) {
      const project = this.selectedProject$.getValue();
      const ret = schedule.information.map((it) => {
        const worker = workers.find((w) => w.user.guid === it.user);
        let hasProject = true;
        if (worker && project) {
          hasProject = schedule.projects.filter((p) => p.project === project.guid && p.user === worker.user.guid)?.length > 0;
        }
        if (hasProject && worker && it.text) {
          return `${worker.user.displayName}: ${it.text}`;
        } else {
          return '';
        }
      });
      information.push(...ret);
    }
    if (timesheets) {
      const ret = timesheets.map((ts) => {
        if (ts.information) {
          const worker = workers.find((w) => w.user.guid === ts.user);
          return `${worker.user.displayName}: ${ts.information}`;
        }
      }).filter((info) => info != null);
      information.push(...ret);
    }
    return information;
  }

  private _getOverTime50(timesheets: Timesheet[]): string {
    const over50 = getOver50(timesheets);
    return over50 > 0 ? reportMillisToMinutesAndSeconds(over50).toFixed(2).replace('.', ',') : '';
  }

  private _getOverTime100(timesheets: Timesheet[]): string {
    const over100 = getOver100(timesheets);
    return over100 > 0 ? reportMillisToMinutesAndSeconds(over100).toFixed(2).replace('.', ',') : '';
  }

  private _getOverTime50All(sheets: ISiteTimesheet[]): string {
    const allTimesheets = sheets.map((sheet) => sheet.timesheets).flat();
    return this._getOverTime50(allTimesheets);
  }

  private _getOverTime100All(sheets: ISiteTimesheet[]): string {
    const allTimesheets = sheets.map((sheet) => sheet.timesheets).flat();
    return this._getOverTime100(allTimesheets);
  }

  private _getPersons(timesheets: Timesheet[]): string {
    if (!timesheets.length) {
      return '';
    }
    const persons = new Set<string>();
    timesheets.map((timesheet) => persons.add(timesheet.user));
    return persons.size.toString();
  }

  private _getSummary(userGuid: string, sheets: ISiteTimesheet[]): string {
    const allTimesheets = sheets.map((sheet) => sheet.timesheets).flat();
    const timesheets = allTimesheets.filter((timesheet) => timesheet.user === userGuid);
    return getDurationsAsString(timesheets);
  }

  private _getAllSummary(sheets: ISiteTimesheet[]): string {
    const allTimesheets = sheets.map((sheet) => sheet.timesheets).flat();
    return getDurationsAsString(allTimesheets);
  }

  private _hasFlagged(user: User, timesheets: Timesheet[]) {
    return timesheets.filter((ts) => ts.user === user?.guid).some((ts) => ts.flagged);
  }

  onNewTimesheet(site: Site) {
    this.plusService.onNewTimesheet(site);
  }

  onSelect(selected: Site) {
    this.selectedSite$.next(selected);
    if (!this.startDay()) {
      this.selectToday();
    }
  }

  onDayChange(startDate: Date, endDate: Date) {
    if (startDate) {
      this.startDay.set(format(startDate, selectedDayFormat));
      this.startDate$.next(startDate);
    }
    if (endDate) {
      this.endDay.set(format(endDate, selectedDayFormat));
      this.endDate$.next(endDate);
    }
  }

  startEdit(sheet: ISiteTimesheet, user: User, role: Roles, allProjects: SiteProject[]) {
    if ([Roles.superAdmin, Roles.admin].includes(role)) {
      const project = this.selectedProject$.getValue();
      const projects = new Set(sheet.timesheets.map((ts) => ts.project));
      if (project == null && projects.size > 1) {
        const items = allProjects.filter((it) => Array.from(projects).includes(it.guid));
        this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items } }).then((m) => {
          m.present();

          m.onDidDismiss<SiteProject>().then((data) => {
            if (data.data) {
              const projectSheet = Object.assign({}, sheet, {
                timesheets: sheet.timesheets
                  .filter((it) => it.project === data.data.guid)
                  .filter((it) => it.user === user.guid),
              });
              this.modalCtrl.create({
                component: SetTimesheetComponent,
                componentProps: { sheet: projectSheet, user, site: this.selectedSite$.value, proejct: data.data },
              }).then((mm) => {
                mm.present();
                mm.onDidDismiss().then((sdata) => {
                  if (sdata.data) {
                    this.updateService.update();
                  }
                });
              });
            }
          });
        });
      } else {
        this.modalCtrl.create({
          component: SetTimesheetComponent,
          componentProps: { sheet, user, site: this.selectedSite$.value, project },
        }).then((m) => {
          m.present();
          m.onDidDismiss().then((data) => {
            if (data.data) {
              this.updateService.update();
            }
          });
        });
      }
    }
  }

  onInformation(sheet: ISiteTimesheet, schedule$: Observable<Schedule>, projects: SiteProject[]) {
    schedule$.pipe(take(1)).subscribe((schedule) => {
      if (sheet.timesheets.length > 0 || schedule != null) {
        const workers = this.workers().map((w) => w.user);
        this.modalCtrl.create({
          component: SetInformationComponent,
          componentProps: { sheet, schedule, workers, projects },
        }).then((m) => {
          m.present();

          m.onDidDismiss<IInformation>().then((data) => {
            if (data.data) {
              if (data.data.type === 'timesheet') {
                this.timesheetsService.update(data.data.timesheet);
              } else {
                this.schedulesService.update(data.data.schedule);
              }
            }
          });
        });
      }
    });
  }

  openTimesheet(user: User, timesheets: Timesheet[]) {
    const timesheet = timesheets.filter((ts) => ts.user === user.guid).find((ts) => ts.flagged);
    this.modalCtrl.create({
      component: TimesheetDialogComponent,
      componentProps: { timesheet, sites: [this.selectedSite$.value] },
    }).then(async (m) => m.present());
  }

  customPrint(site: Site) {
    this.loadingService.showLoading(undefined, 'general.printing', ['full-loading']);
    setTimeout(() => {
      const name = `${this.selectedSite$.value.name}-${this.selectedSite$.value.client ?? ''}_${this.startDay()}-${this.endDay()}`;
      const parent = document.getElementById(site.guid + '-container');
      const style = document.createElement('style');
      style.prepend(css);
      parent.prepend(style);
      const element = document.getElementById(site.guid + '-report-print');
      this.printService.printDoc(name, element, css).then(() => {
        style.remove();
        this.loadingService.hideLoading();
      }, (e) => {
        console.error(e);
        style.remove();
        this.loadingService.hideLoading();
      });
    }, 50);
  }

  selectSite(sites: Site[]) {
    this.modalCtrl.create({ component: SelectSiteDialog, componentProps: { sites, isDisabledToggle: true } }).then((m) => {
      m.present();

      m.onDidDismiss<Site>().then((data) => {
        if (data.data) {
          this.onSelect(data.data);
        }
      });
    });
  }

  selectProject(items: SiteProject[]) {
    const clearButton = this.selectedProject$.getValue() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedProject$.next(null);
          } else {
            this.selectedProject$.next(data.data);
          }
        }
      });
    });
  }

  toggleShowInformation() {
    this.showInformation.update((val) => !val);
  }

  back() {
    this.navigationService.navigateToHome();
  }
}
