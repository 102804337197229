export const css = `
  table.sheet {
    width: 100%;
  }
  tr { page-break-inside: avoid; page-break-after: auto; }
  .flex {
    display: flex;

    &.title {
      gap: 10em;
      margin-bottom: 1em;
    }
  }
  .no-print { display: none; }
  .print { opacity: 1 !important; height: initial !important; }
  .signature-container {
    height: 75px;
    border-bottom: 1px solid #222;
    width: 250px;
  }
  .sticky { position: initial !important; }
  .checked { background: #fff !important; }
  .desktop { display: block !important; width: 1520px; font-family: Roboto, "Helvetica Neue", sans-serif; }
`;
