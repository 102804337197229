import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours',
})
export class HoursPipe implements PipeTransform {

  transform(value: string | number): string {
    if (value == null || value === '') {
      return '';
    }
    if (typeof value === 'string') {
      if (value.includes('-')) {
        return value;
      }
      const [hours, mins] = value.split(',');
      return `${hours}h ${mins !== '00' ? mins + 'min' : ''}`;
    } else {
      const [hours, mins] = value.toFixed(2).split('.');
      return `${hours}h ${mins !== '00' ? mins + 'min' : ''}`;
    }
  }
}
