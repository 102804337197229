import { CommonModule } from '@angular/common';
import { Component, WritableSignal, computed, signal } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectSiteDialog } from 'src/app/components/select-site/select-site.dialog';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { User } from 'src/app/models/user.model';
import { HoursPipe } from 'src/app/pipes/duration/hours.pipe';
import { ISiteTimesheet, editTimesheet, getWorkingHours } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ClientsService } from 'src/app/services/clients.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { fieldSorter } from 'src/app/utility/sort';
import { hoursArray } from 'src/app/utility/time';

@Component({
  selector: 'app-set-timesheet-dialog',
  templateUrl: './set-timesheet.dialog.html',
  styleUrls: ['./set-timesheet.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    HoursPipe,
  ],
})
export class SetTimesheetComponent {

  private initialProject: SiteProject;
  site$ = new BehaviorSubject<Site>(null);
  user$ = new BehaviorSubject<User>(null);
  projectAnother = signal<SiteProject>(null);

  selectedProject = signal<SubItem>(null);
  mainHours = signal(0);
  split1Hours = signal(0);
  split2Hours = signal(0);
  siteAnother = signal<Site>(null);
  mainSheet = signal<ISiteTimesheet>(null);
  showSplit = signal(false);

  worksites$: Observable<Site[]> = this.sitesService.getAdminList().pipe(
    take(1),
    map((sites) => sites.filter((s) => !s.archived)),
    switchMap((sites) => combineLatest([this.clientsService.getList(), of(sites)])),
    map(([clients, sites]) => sites.map((site) => {
      site.clientName = clients.find((c) => c.guid === site.client)?.name ?? site.client;
      return site;
    })),
    map((sites) => sites?.sort(fieldSorter(['clientName', 'name']))),
    shareReplay(1),
  );

  projects$ = combineLatest([this.site$.asObservable(), this.user$.asObservable()]).pipe(
    filter(([site, user]) => site != null && user != null && user.contractors?.length > 0),
    switchMap(([site, user]) => this.siteProjectsService.getList({ site: site.guid, contractor: user.contractors[0] })),
    tap((projects) => {
      const currentProject = this.initialProject?.guid ??
        this.mainSheet().timesheets?.find((it) => it.user === this.user$.getValue().guid && it.site === this.site$.getValue().guid)?.project ?? null;
      const project = projects?.find((it) => it.guid === currentProject) ?? null;
      this.selectedProject.set({ guid: project?.guid, name: project?.name ?? '' });
    }),
    shareReplay(1),
  );

  readonly hoursArray = hoursArray;

  checked = computed(() => this.mainSheet()?.timesheets.every((it) => it.checked));
  disableButton = computed(() => (this.showSplit() && !this.siteAnother() && !this.projectAnother()) || !this.selectedProject()?.name);

  constructor(
    private clientsService: ClientsService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private siteProjectsService: SiteProjectsService,
    private timesheetsService: TimesheetsService,
    private translate: TranslateService,
    navParams: NavParams,
  ) {
    this.mainSheet.set(navParams.get('sheet'));
    const site = navParams.get<Site>('site');
    const user = navParams.get<User>('user');
    this.initialProject = navParams.get<SiteProject>('project');
    this.site$.next(site);
    this.user$.next(user);
    const hours = getWorkingHours(user.guid, this.mainSheet().timesheets, 'user').replace(',', '.');
    this.mainHours.set(+hours);
    this.split1Hours.set(+hours);
  }

  onDuration(value: number | string, updateSignal: WritableSignal<number>) {
    if (value === null) {
      Dialog.prompt({
        title: this.translate.instant('tickets.otherTime'),
        message: this.translate.instant('timesheet.help'),
      }).then((selectedValue) => {
        if (selectedValue) {
          const hours = selectedValue.value.replace(',', '.');
          this.onDuration(+hours, updateSignal);
        }
      });
      return;
    }
    if (value == null) {
      value = 0;
    }
    updateSignal.set(+value);
  }

  selectProject(items: SiteProject[]) {
    const clearButton = this.selectedProject() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedProject.set(null);
          } else {
            this.selectedProject.set(data.data);
          }
        }
      });
    });
  }

  changeSite(sites: Site[]) {
    this.modalCtrl.create({ component: SelectSiteDialog, componentProps: { sites } }).then((m) => {
      m.present();
      m.onDidDismiss<Site>().then((data) => {
        if (data.data) {
          this.site$.next(data.data);
          this.selectedProject.set(null);
        }
      });
    });
  }

  onAnotherSiteSelect(items: Site[]) {
    const clearButton = this.siteAnother() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<Site>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.siteAnother.set(null);
          } else {
            this.siteAnother.set(data.data);
          }
        }
      });
    });
  }

  onSelectSplitProject(items: SiteProject[]) {
    const clearButton = this.projectAnother() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.projectAnother.set(null);
          } else {
            this.projectAnother.set(data.data);
          }
        }
      });
    });
  }

  startSplit() {
    this.showSplit.set(true);
  }

  cancelSplit() {
    this.siteAnother.set(null);
    this.projectAnother.set(null);
    this.showSplit.set(false);
  }

  onEditTimesheet(accept = false, invoiced = false, wontInvoice = false) {
    const sheet = this.mainSheet();
    const site = this.site$.getValue();
    const user = this.user$.getValue();
    const project = this.selectedProject();
    if (this.showSplit()) {
      const anotherSheet = Object.assign({}, sheet, { timesheets: [] });
      const input1 = { value: this.split1Hours().toString().replace('.', ',') };
      const input2 = { value: this.split2Hours().toString().replace('.', ',') };
      editTimesheet(this.timesheetsService, site, sheet, input1, user, accept, invoiced, wontInvoice, project);
      editTimesheet(this.timesheetsService, this.siteAnother(), anotherSheet, input2, user, accept, invoiced, wontInvoice,
        { guid: this.projectAnother().guid, name: this.projectAnother().name });
    } else {
      const input = { value: this.mainHours().toString().replace('.', ',') };
      sheet.timesheets.map((ts) => {
        ts.site = site.guid;
      });
      editTimesheet(this.timesheetsService, site, sheet, input, user, accept, invoiced, wontInvoice, project);
    }
    this._modal.dismiss(true);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
